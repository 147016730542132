﻿let defaults = {
  backgroundimageTitle: ".backgroundimage-title",
  backgroundimage: ".backgroundimage"
}

const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const getTypedLocationWmsUrl = function (xCoord, yCoord, m_pr_pixel, product) {

  var width = 1920;
  var height = 940;

  var bbox = eval(xCoord - width / 2 * m_pr_pixel) + "," + eval(yCoord - height / 2 * m_pr_pixel) + "," + eval(xCoord + width / 2 * m_pr_pixel) + "," + eval(yCoord + height / 2 * m_pr_pixel);

  let url;

  if (product == "seplan") {
    url = "https://services.drift.kortinfo.net/kortinfo/services/Wms.ashx?Site=SEPlan&Page=sverige_tatorter_kommunkod&Service=WMS&Version=1.1.1&Srs=EPSG:3006&Request=getmap&Layers=TL1358253&BBox=" + bbox + "&Width=" + width + "&Height=" + height + "&Transparent=false&Format=image/jpeg";
  } else {
    url = "https://services.drift.kortinfo.net/kortinfo/services/Wms.ashx?Site=DKPlan&Page=DKplan_Kladdelag&Service=WMS&Version=1.1.1&Srs=EPSG:25832&Request=getmap&Layers=B3294&BBox=" + bbox + "&Width=" + width + "&Height=" + height + "&Transparent=false&Format=image/jpeg";
  };

  return url;

}

const randomInteger = function (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const generateImage = function (byer, product) {
  return new Promise((resolve, reject) => {

    let rnd = randomInteger(0, byer.length - 1);

    let selectedBy = byer[rnd];

    let visueltCenter = selectedBy.visueltcenter

    if (product != "seplan") {
      visueltCenter = proj4("EPSG:25832", visueltCenter);
    }

    let url = getTypedLocationWmsUrl(visueltCenter[0], visueltCenter[1], 1, product);

    var img = new Image();
    img.src = url;

    img.onload = () => {
      $(defaults.backgroundimage).html(img);
      $(`${defaults.backgroundimageTitle} ${defaults.backgroundimageTitle}__text`).text(selectedBy.primærtnavn);
      resolve(img.height)
    };

    img.onerror = reject

  })

}

let init = async function (isSEplan, config) {

  let product = isSEplan ? "seplan" : "dkplan";
  let byer = [];

  if (!isSEplan) {

    proj4.defs('EPSG:25832', "+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs");

    byer = await $.ajax("https://api.dataforsyningen.dk/steder?hovedtype=Bebyggelse&undertype=by&kommunekode=" + DKPlanSettings.kommunekode, {
      xhrFields: {
        withCredentials: false
      }
    });

  } else {
    //https://services.drift.kortinfo.net/kortinfo/services/Wms.ashx?Site=DKPlan&Page=DKplan_Kladdelag&Service=WMS&Version=1.1.1&Srs=EPSG:25832

    //proj4.defs("EPSG:3006", "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");

    //byer = await $.ajax(`https://services.drift.kortinfo.net/kortinfo/services/Feature.ashx?site=SEPlan&page=sverige_tatorter_kommunkod&layer=TL1357474&srs=EPSG:4326&output=geojson&filter=<equals><column name='KOMMUN'/><string>${DKPlanSettings.kommunekode}</string></equals>`, {
    //  xhrFields: {
    //    withCredentials: true
    //  }
    //});

    //if (byer.features.length == 0) {
    //  return;
    //}

    //byer = byer.features.map(b => {
    //  return {
    //    visueltcenter: [b.properties.X_koord, b.properties.Y_koord],
    //    primærtnavn: b.TATORT
    //  }
    //})

    
   
    $(defaults.backgroundimage).addClass("backgroundimage--is-seplan");
    $(`${defaults.backgroundimageTitle} ${defaults.backgroundimageTitle}__text`).text("NIRAS.SE");
   
  }

  $(defaults.backgroundimageTitle).on("click", function () {
    $(`${defaults.backgroundimageTitle}__text`).toggle();
  })

  if (!isSEplan) {
    do {
      await generateImage(byer, product);

      await sleep(10000);

    } while (true);
  }
}


export default {
  init
};