﻿(function (exports, API) {


  exports.DKplanApp = exports.DKplanApp || {};

  var app = exports.DKplanApp;


  var defaults = {
    host: undefined,
    kunde: undefined
  }


  var init = function (config) {

    for (var prop in config) {
      if (config.hasOwnProperty(prop)) {
        defaults[prop] = config[prop];
      }
    }

  }

  var getNewsFeeds = function () {
    return API.call(defaults.host + defaults.kunde + "/newsfeed/GetNewsfeeds", {
      method: "GET",
      data: {

      }
    });
  }

  var getNewsItem = function (newsId) {
    return API.call(defaults.host + defaults.kunde + "/newsfeed/GetNewsItem", {
      method: "GET",
      data: {
        cmsId: newsId
      }
    });
  }

  var getNumberOfNewNewsItem = function (newsId) {
    return API.call(defaults.host + defaults.kunde + "/newsfeed/GetNewNewsitems", {
      method: "GET",
      data: {

      }
    });
  }

  var getNewsIsReadStatus = function (login) {
    return API.call(defaults.host + defaults.kunde + "/newsfeed/GetNewsIsReadStatus?kunde=" + kunde + "&login=" + login, {
      method: "GET",
    })
  }

  var updateNewsIsReadStatus = function (id, login) {
    return API.call(defaults.host + defaults.kunde + "/newsfeed/UpdateNewsIsReadStatus?kunde=" + kunde + "&login=" + login, {
      method: "POST",
      contentType: "application/json",
      data: JSON.stringify({
        UmbracoNodeId: id,
        IsRead: 1
      })
    })
  }

  var searchSupport = function (term) {
    return API.call(defaults.host + defaults.kunde + "/newsfeed/SearchSupport", {
      method: "GET",
      data: {
        searchterm: term
      }
    });
  }

  var readAllNews = function (login, ids) {
    return API.call(defaults.host + defaults.kunde + "/newsfeed/ReadAllNews?kunde=" + defaults.kunde + "&login=" + login, {
      method: "POST",
      contentType: "application/json",
      data: JSON.stringify({
        UmbracoNodeIds: ids,
      })
    })
  }

  app.API = app.API || {};

  app.API.newsfeed = app.API.newsfeed || {
    init: init,
    getNewsFeeds: getNewsFeeds,
    getNewsItem: getNewsItem,
    getNewsIsReadStatus: getNewsIsReadStatus,
    getNumberOfNewNewsItem: getNumberOfNewNewsItem,
    updateNewsIsReadStatus: updateNewsIsReadStatus,
    searchSupport: searchSupport,
    readAllNews: readAllNews
  };

})(window, DKplanApp.API.base);