﻿(function(exports, $) {
  var unauthorizedState = false;

  $.ajaxSetup({
    xhrFields: {
      withCredentials: true // This setting passes cookies along the request, including our authentication cookie.
    }
  });

  $(document).ajaxError(function(event, jqxhr, settings, thrownError) {
    if (jqxhr.status == "401" && !unauthorizedState) {
      unauthorizedState = true;
      alert('Dit login er udløbet, klik på "OK" for at logge ind igen.');
      window.location.href = "/Identity/Account/Login?returnUrl=" + window.location.pathname + window.location.search;
    }
  });

  exports.DKplanApp = exports.DKplanApp || {};

  var app = exports.DKplanApp;

  var call = function(url, settings) {
    if (typeof url === "string" || url instanceof String) {
      if (url !== "") {
        settings.url = url;
      }
    } else {
      settings = url; //single argument jQuery.ajax
    }

    return $.ajax(settings);
  };

  app.API = app.API || {};

  app.API.base = app.API.base || {
    call: call
  };
})(window, jQuery);
